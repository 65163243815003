import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { Container, Paper } from '../theme';
import { sxPropType } from '../../proptypes/misc';

function PaperSection({ children, themeBase, sx, ...other }) {
  return (
    <Box
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.common.white,
          paddingBottom: '20px',
          [theme.breakpoints.up('md')]: {
            paddingBottom: '40px',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...other}
    >
      <Container component="section" narrow>
        <Paper
          elevation={6}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              margin: '0 -56px',
            },
          })}
          background={themeBase === 'light' ? 'default' : 'dark'}
        >
          {children}
        </Paper>
      </Container>
    </Box>
  );
}

PaperSection.propTypes = {
  children: PropTypes.node.isRequired,
  themeBase: PropTypes.oneOf(['light', 'dark']),
  sx: sxPropType,
};

PaperSection.defaultProps = {
  themeBase: 'dark',
  sx: [],
};

export default PaperSection;
