import React from 'react';
import { useIntl, FormattedMessage } from 'gatsby-plugin-react-intl';

import { Container, Button } from '../components/theme';
import { BlockHeadline, Layout } from '../components/layout';
import Seo from '../components/Seo';

function ErrorPage() {
  const intl = useIntl();
  return (
    <Layout lightTheme>
      <Container sx={{ marginTop: '100px', marginBottom: '200px' }} narrow>
        <BlockHeadline
          overline={intl.formatMessage({ id: 'pageNotFound' })}
          content={intl.formatMessage({ id: '404' })}
          variant="h1"
          sizeRestriction="normal"
        />
        <Button color="secondary" size="small" variant="contained" to="/">
          <FormattedMessage id="backToHomepage" />
        </Button>
      </Container>
    </Layout>
  );
}

export default ErrorPage;

export function Head() {
  // intl.formatMessage({ id: 'pageNotFound' });
  // can't use provider until gatsby 5, so we have to hardcode the description
  // see https://github.com/gatsbyjs/gatsby/issues/36458#issuecomment-1236558468 for a related problem
  const description = process.env.CURRENT_LANGUAGE === 'de' ? 'Seite nicht gefunden' : 'page not found';

  return <Seo title="404" description={description} />;
}
